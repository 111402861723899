import { Controller } from "stimulus";

export default class extends Controller {
	static targets = [ "titleProvider", "subtitleProvider", "titleDisplay", "subtitleDisplay", "bar" ];
	static classes = [ "scrolled" ];
	
	connect() {		
		if (this.hasTitleProviderTarget && this.hasTitleDisplayTarget) {
			this.titleDisplayTarget.textContent = this.titleProviderTarget.textContent;
		}
		
		if (this.hasSubtitleProviderTarget && this.hasSubtitleDisplayTarget) {
			this.subtitleDisplayTarget.replaceWith(this.subtitleProviderTarget);
		}
	}
	
	layout() {
		if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
			this.barTarget.classList.add(this.scrolledClass);
			
		} else {
			this.barTarget.classList.remove(this.scrolledClass);
			
		}
	}
}