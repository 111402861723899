import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    console.log("Connected", this.element);
  }
  
  reset() {
    this.element.reset()
  }
}