import { Controller } from "stimulus"

export default class extends Controller {
	static targets = [ "display" ];
	static values = {
		start: String,
		startDuration: Number
	}
	
	initialize() {
		this.intervalID = null;
	}
	
	connect() {
		this.update();
		this.intervalID = setInterval(() => this.update(), 1000);
	}
	
	disconnect() {
		window.clearInterval(this.invervalID);
		this.invervalID = null;
	}
	
	update() {
		let duration = this.calculateDuration();
		this.displayTarget.innerText = this.formatDuration(duration);
	}
	
	calculateDuration() {
		let duration = moment.duration(moment() - moment(this.startValue));
		duration.add(this.startDurationValue, "seconds");
		
		return duration;
	}
	
	formatDuration(duration) {
		let str = duration.format("hh:mm:ss", { forceLength: true });
		if (duration.hours() == 0) {
			if (duration.minutes() == 0) {
				str = "00:" + str;
			}
			str = "00:" + str;
		}
		
		return str;
	}
}