import { Controller } from "stimulus";
import flatpickr from "flatpickr";
import { German } from "flatpickr/dist/l10n/de.js";

export default class extends Controller {
	static values = { picker: Object };
	
	connect() {
		flatpickr(this.element, {
			locale: German,
			enableTime: true,
			dateFormat: "d.m.Y H:i:S",
			time_24hr: true,
			parseDate: function(dateString) {
				return moment(dateString).toDate();
			}
		});
	}
}