import { Controller } from "stimulus"
import datepicker from 'js-datepicker'

export default class extends Controller {
	static values = { picker: Object, affectingTarget: String, affectingValue: Object };
	
	connect() {
		let _controller = this;
		this.pickerValue = datepicker(this.element, {
			formatter: (input, date, instance) => {
				input.value = moment(date).format("DD.MM.YYYY");
			},
			onSelect: (instance, date) => {
				_controller.selected(instance, date);
			}
		});
	}
	
	selected(_picker, date) {
		if (this.hasAffectingTargetValue && this.hasAffectingValueValue) {
			let affectingTarget = document.querySelector(this.affectingTargetValue);
			if (!affectingTarget) {
				return;
			}
			
			let originalDate = moment(date);
			let affectedDate;
			if (this.affectingValueValue.operation == "add") {
				affectedDate = originalDate.add(this.affectingValueValue.value, this.affectingValueValue.type);
			} else if (this.affectingValueValue.operation == "subtract") {
				affectedDate = originalDate.subtract(this.affectingValueValue.value, this.affectingValueValue.type);
			}
			
			if (affectedDate !== null) {
				affectingTarget.value = affectedDate.format("DD.MM.YYYY");
			}
		}
	}
}