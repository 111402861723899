import { Controller } from "stimulus";

export default class extends Controller {
	connect() {
		console.log("CONNECTED!");
	}
	
	add() {
		event.preventDefault();
		
		let objectClass = event.currentTarget.dataset.objectClass;
		let associationPath = event.currentTarget.dataset.associationPath;
		let addedIndex = document.querySelectorAll(`.nested_${associationPath}`).length;
		
		let template;
		if (event.currentTarget.dataset.scope) {
			template = document.querySelector(`${event.currentTarget.dataset.scope} #${associationPath}_template`);
		} else {
			template = document.querySelector(`#${associationPath}_template`);
		}
		let target = event.currentTarget.dataset.insertInto;
		let templateHtml = template.innerHTML;
		let indexPlaceholder = `__${associationPath}_index__`;
		templateHtml = templateHtml.replace(new RegExp(indexPlaceholder, "g"), addedIndex);
		templateHtml = templateHtml.replace(new RegExp("__nested_field_for_replace_with_index__", "g"), addedIndex);
		
		let parsedTemplate = document.createElement("div");
		parsedTemplate.innerHTML = templateHtml.trim()
		parsedTemplate = parsedTemplate.firstChild;
		
		if (target) {
			document.querySelector(target).insertAdjacentElement("beforeend", parsedTemplate);
		} else {
			template.insertAdjacentElement("beforebegin", parsedTemplate);
		}
		
		return false;
	}
	
	remove() {
		event.preventDefault();
		
		if (event.currentTarget.disabled) {
			return;
		}
		
		let objectClass = event.currentTarget.dataset.objectClass;
		let deleteAssociationFieldName = event.currentTarget.dataset.deleteAssociationFieldName;
		let removedIndex = parseInt(deleteAssociationFieldName.match('(\\d+\\]\\[_destroy])')[0].match('\\d+')[0]);
		let nestedFieldsContainer = this.getParents(event.currentTarget, ".nested_fields")[0];
		let deleteField = nestedFieldsContainer.querySelector(`input[type='hidden'][name='${deleteAssociationFieldName}']`);
		if (deleteField) {
			deleteField.value = '1';
		} else {
			let deleteInput = document.createElement("input");
			deleteInput.setAttribute("type", "hidden");
			deleteInput.setAttribute("name", deleteAssociationFieldName);
			deleteInput.value = "1";
			nestedFieldsContainer.insertAdjacentElement("beforebegin", deleteInput);
		}
		
		nestedFieldsContainer.style.display = "none";
		//nestedFieldsContainer.querySelectorAll("input[required]:hidden, select[required]:hidden, textarea[required]:hidden").forEach((elem, index) => {
		// 	elem.removeAttribute("required");
		//});
	}
	
	getParents(el, parentSelector) {
		if (parentSelector === undefined) {
			parentSelector = document;
		}
		var parents = [];
		var p = el.parentNode;
		while (!p.matches(parentSelector)) {
			var o = p;
			parents.push(o);
			p = o.parentNode;
		}
		parents.push(p);
		parents = parents.reverse();
		return parents;
	}
}