import { Controller } from "stimulus";
import { createPopper } from '@popperjs/core';

export default class extends Controller {
	static targets = [ "dropdown", "input", "initialFocus", "display", "anchor" ];
	static popper = null;
	
	connect() {
		this.setupPosition();
	}
	
	disconnect() {
		this.close()
	}
	
	show() {
		this.setupPosition();
		this.dropdownTarget.classList.remove("dropdown--closed");
		if (this.hasInitialFocusTarget) {
			this.initialFocusTarget.focus();
		}
	}
	
	closeOnClickOutside({ target }) {
		if (this.element.contains(target)) return
		// if (this.forceMenuOpen) return
	
		this.close();
	  }
	
	close() {
		this.dropdownTarget.classList.add("dropdown--closed");
	}
	
	navigate() {
		event.preventDefault();
		const params = event.currentTarget.dataset;		
		switch(params.dropdownTypeParam) {
			case "input":
				this.setInput(params.dropdownValueParam, { displayValue: params.dropdownDisplayParam });
				this.close();
				break;
			default:
				console.error("[DropdownController#navigate]: No matching navigation type.", params.dropdownTypeParam);
		}
	}
	
	setInput(value, additional = {}) {
		if (!this.hasInputTarget) return;
		
		this.inputTarget.value = value;
		if (additional.displayValue && this.hasDisplayTarget) {
			this.displayTarget.textContent = additional.displayValue;
		}
	}
	
	setupPosition() {
		if (this.hasAnchorTarget) {
			this.popper = createPopper(this.anchorTarget, this.dropdownTarget, {
				placement: "bottom-end",
				strategy: "fixed",
				modifiers: [
					{
						name: "offset",
						options: {
							offset: [0, 8]
						}
					}
				]
			});
		}
	}
}