// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// import Rails from "@rails/ujs"
import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"
import "@hotwired/turbo-rails"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "./src/direct_upload"
import "jquery"
import moment from "moment-timezone";
import duration from "moment-duration-format";
import "./src/drp.min"
import Chart from 'chart.js/auto';
import 'chartjs-adapter-moment';
import "trix";
import "@rails/actiontext";
import { createPopper } from '@popperjs/core';

// Rails.start()
const application = Application.start()
const context = require.context("./controllers", true, /\.js$/)
application.load(definitionsFromContext(context))
ActiveStorage.start()
moment.locale("de");
duration(moment);
global.Chart = Chart;
global.moment = moment;