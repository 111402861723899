import { Controller } from "stimulus"

export default class extends Controller {
	static targets = [ "chartContext" ]
	static values = {
		data: Object,
		type: String
	}
	
	connect() {
		moment.locale("de");
		const chart = new Chart(this.chartContextTarget, {
			type: this.typeValue,
			data: this.dataValue,
			options: {
				locale: "de",
				scales: {
					y: {
						stacked: true,
						beginAtZero: true,
						min: 0,
						suggestedMax: 10,
						padding: 10,
						grid: {
							drawBorder: false
						},
						ticks: {
							stepSize: 2
						}
					},
					x: {
						stacked: true,
						type: 'time',
						adapters: {
							date: {
								locale: 'de'
							}
						},
						time: {
							tooltipFormat: 'DD. MMMM',
							minUnit: 'day',
							displayFormats: {
								day: 'DD. MMMM',
								month: 'DD.MM.',
								quarter: '[Q]Q \'YY',
								year: 'YYYY'
							}
						},
						grid: {
							drawOnChartArea: false,
							drawTicks: true
						},
						ticks: {
							autoSkip: true
						}
					}
					// yAxes: [{
					// 	ticks: {
					// 		beginAtZero: true,
					// 		min: 0,
					// 		suggestedMax: 10,
					// 		padding: 10,
					// 		stepSize: 2
					// 	},
					// 	gridLines: {
					// 		drawBorder: false
					// 	}
					// }],
					// xAxes: [{
					// 	ticks: {
					// 		padding: 10	
					// 	},
					// 	gridLines: {
					// 		drawOnChartArea: false,
					// 		drawTicks: true
					// 	}
					// }]
				},
				responsive: true,
				maintainAspectRatio: false,
				onClick: (e) => {
					const elements = chart.getElementsAtEventForMode(e, 'nearest', { intersect: true }, true);
					if (elements.length) {
						const elem = elements[0];
						const label = chart.data.labels[elem.index];
						let htmlElement = document.querySelector("[data-day='"+ label +"']");
						if (htmlElement) {
							htmlElement.scrollIntoView({
								block: "center",
								behavior: "smooth"
							});
						}
					}
				},
				plugins: {
					legend: {
						display: false
					},
					tooltip: {
						displayColors: false,
						callbacks: {
							label: function(context) {
								let label = context.dataset.label || "";
								
								if (label) {
									label += ': ';
								}
								if (context.parsed.y !== null) {
									label += new Intl.NumberFormat("de-DE", {
										style: "unit",
										unit: "hour",
										unitDisplay: "narrow",
										maximumFractionDigits: 2
									}).format(context.parsed.y);
								}
								
								return label;
							}
						}
					}
				}
			}
		})
	}
}