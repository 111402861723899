import { Controller } from "stimulus";

export default class extends Controller {
	static targets = [ "vanisher", "overtaker", "overtakerWrapper" ];
	static values = {
		forceClosed: { type: Boolean, default: false }
	}
	
	connect() {
	}
	
	disconnect() {
		this.reset();
	}
	
	overtakerTargetConnected(overtaker) {
		if (this.forceClosedValue === true) return;
		
		if (this.vanisherTargets.length > 0) {
			this.forceClosedValue = false;
			
			this.vanisherTargets.forEach(vanisher => {
				vanisher.classList.add("take-over--vanish");
			});
			window.setTimeout(() => {
				overtaker.classList.remove("take-over-overtaker--initial");
			}, 1);
			if (this.hasOvertakerWrapperTarget) {
				this.overtakerWrapperTarget.classList.add("overtaken");
			}
			let input = overtaker.querySelector("input[type=text]");
			if (input) {	
				input.focus();
				input.selectionStart = input.selectionEnd = input.value.length;
			}
		}
	}
	
	overtakerTargetDisconnected(_overtaker) {
		if (this.vanisherTargets.length > 0) {
			this.vanisherTargets.forEach(vanisher => {
				vanisher.classList.remove("take-over--vanish");
			});
		}
		
		if (this.hasOvertakerWrapperTarget) {
			this.overtakerWrapperTarget.classList.remove("overtaken");
		}
	}
	
	reset() {
		this.forceClosedValue = true;
		if (this.vanisherTargets.length > 0) {
			this.vanisherTargets.forEach(vanisher => {
				vanisher.classList.remove("take-over--vanish");
			});
		}
		
		if (this.overtakerTargets.length > 0) {
			this.overtakerTargets.forEach(overtaker => {
				overtaker.classList.add("take-over-overtaker--initial");
			})
		}
		
		if (this.hasOvertakerWrapperTarget) {
			this.overtakerWrapperTarget.classList.remove("overtaken");
		}
	}
	
	takeOver() {
		if (this.vanisherTargets.length > 0) {
			this.vanisherTargets.forEach(vanisher => {
				vanisher.classList.add("take-over--vanish");
			});
		}
		
		if (this.overtakerTargets.length > 0) {
			this.overtakerTargets.forEach(overtaker => {
				overtaker.classList.remove("take-over-overtaker--initial");
			})
		}
		
		if (this.hasOvertakerWrapperTarget) {
			this.overtakerWrapperTarget.classList.add("overtaken");
			let input = this.overtakerWrapperTarget.querySelector("input[type=text]");
			if (input) {	
				input.focus();
				input.selectionStart = input.selectionEnd = input.value.length;
			}
		}
		
	}
	
	closeOnClickOutside({ target }) {
		if (this.element.contains(target)) return
		
		this.reset();
	}
	
	closeOnFocusOutside({ target }) {
		if (this.element.contains(target)) return
		
		this.reset();
	}
}