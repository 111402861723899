import { Controller } from "stimulus"

export default class extends Controller {
	static targets = [ "wedgeStart", "wedgeEnd", "marker" ];
	static values = {
		progress: Number
	}
	
	connect() {
		let markerDegrees = this.progressValue * 3.6;
		let startDegrees = 0;
		let endDegrees = 0;
		
		if (this.progressValue >= 50.0) {
			startDegrees = 180;
			if (this.progressValue >= 100.0) {
				endDegrees = 50.0 * 3.6;
			} else {
				endDegrees = (this.progressValue - 50.0) * 3.6;
			}
			
		} else if (this.progressValue > 0) {
			startDegrees = this.progressValue * 3.6;
		}
		
		this.markerTarget.style.transform = `rotateZ(${markerDegrees}deg)`;
		this.wedgeStartTarget.style.transform = `rotateZ(${startDegrees}deg)`;
		this.wedgeEndTarget.style.transform = `rotateZ(${endDegrees}deg)`;
	}
}