import { Controller } from "stimulus";

export default class extends Controller {
	static targets = [ 'scope', 'toFilter' ];
	static values = {
		source: Object,
		initiallySelected: Object
	};
	
	connect() {
		if (this.hasToFilterTarget) {
			this.initiallySelectedValue[this.scopeTarget.value] = this.toFilterTarget.value;
		}
	}
	
	updateFilter() {
		let selectedScope = event.target.value;
		if (!this.hasSourceValue) {
			return;
		}
		
		let options = this.sourceValue[selectedScope];
		if (this.hasToFilterTarget) {
			this.toFilterTarget.options.length = 0;
			let previouslySelectedValue = this.initiallySelectedValue[selectedScope];
			
			for(let index in options) {
				let address = options[index];
				let selected = previouslySelectedValue == address.id;
				this.toFilterTarget.options[this.toFilterTarget.length] = new Option(address.name, address.id, selected, selected);
			}
		}
	}
}