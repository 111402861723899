import { Controller } from "stimulus";

export default class extends Controller {
	static targets = [ "footerContent" ];
	
	connect() {
		if (this.hasFooterContentTarget) {
			let sidebarFooter = document.querySelector(".modal-sidesheet .modal__footer");
			if (sidebarFooter !== undefined) {
				sidebarFooter.innerHTML = this.element.innerHTML;
			}
		}
	}
}