import { Controller } from "stimulus";

export default class extends Controller {
	static targets = [ "sidebarContent", "sidebar", "footerContent", "footer" ];
	
	present(event) {
		event.preventDefault();
		let href = event.currentTarget.getAttribute("href");
		this.sidebarTarget.style.removeProperty("width");
		fetch(href)
			.then(response => response.text())
			.then((html) => {
				this.sidebarContentTarget.innerHTML = html;
				this.sidebarTarget.classList.remove("modal-closed");
				
				if (this.hasFooterContentTarget) {
					this.footerTarget.innerHTML = this.footerContentTarget.innerHTML;
					
					if (this.footerContentTarget.dataset.sidebarLoaderWidthValue) {
						this.sidebarTarget.style.width = this.footerContentTarget.dataset.sidebarLoaderWidthValue;
					}
				}
			}).catch(error => alert(error));
	}
	
	close(event) {
		if (event !== undefined) {
			event.preventDefault();
		}
		this.sidebarTarget.classList.add("modal-closed");
		this.sidebarTarget.style.removeProperty("width");
	}
	
	submitEnd() {
		if (event.detail.success && event.detail.formSubmission.mustRedirect) {
			this.close();
		}
	}
}