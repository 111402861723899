import { Controller } from "stimulus"
import moment from "moment-timezone";

export default class extends Controller {
	static targets = [ "wrapper", "inputFrom", "inputTo" ];
	
	connect() {
		moment.locale("de");
		let fromVal = moment(this.inputFromTarget.value);
		if (!fromVal.isValid()) {
			fromVal = moment();
		}
		let toVal = moment(fromVal).add(1, "month");
		$(this.wrapperTarget).drpicker({
			dateFrom: moment(fromVal),
			dateTo: moment(toVal),
			targetFrom: $(this.inputFromTarget),
			targetTo: $(this.inputToTarget),
			outputFormant: "DD.MM.YYYY",
			header: {
				title: 'Etappenzeitraum'
			}
		});
	}
}